import { template as template_aa7ca109fabe4ed5ada85facd9f97a55 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aa7ca109fabe4ed5ada85facd9f97a55(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
