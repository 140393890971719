import { template as template_15d992a7515349c5a78af25eea7c68ca } from "@ember/template-compiler";
const FKLabel = template_15d992a7515349c5a78af25eea7c68ca(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
