import { template as template_a41f470615ca4eaeacb7535b638f4307 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_a41f470615ca4eaeacb7535b638f4307(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
