import { template as template_eec36c55b84d4e1196e9cd30232c0223 } from "@ember/template-compiler";
const SidebarSectionMessage = template_eec36c55b84d4e1196e9cd30232c0223(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
